// src/Components/Resume.js
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import resumePDF from '../Images/Khoa_Luong_Resume_CO.pdf'; // Adjust the path as needed
import 'react-pdf/dist/esm/Page/TextLayer.css'; // Required for selectable text
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // Optional, for annotations like links

// Set up the PDF.js worker
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Resume = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Determine the width based on screen size, but cap it to fit content better
  const pdfWidth = window.innerWidth < 768 ? window.innerWidth - 40 : 800; // Reduced max width to 600px

  return (
    <div className="resume-section">
      <div className="resume-container">
        <Document
          file={resumePDF} // Your imported PDF file
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.error('Error loading PDF:', error)}
        >
          <Page
            pageNumber={pageNumber}
            width={pdfWidth} // Adjusted width to fit better
            renderTextLayer={true} // Enables selectable text
            renderAnnotationLayer={true} // Optional: renders clickable links if present
          />
        </Document>
        {numPages > 1 && (
          <div className="resume-navigation">
            <button
              onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
              disabled={pageNumber === 1}
            >
              Previous
            </button>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={() => setPageNumber((prev) => Math.min(prev + 1, numPages))}
              disabled={pageNumber === numPages}
            >
              Next
            </button>
          </div>
        )}
        <p>
          Trouble viewing?{' '}
          <a href={resumePDF} target="_blank" rel="noopener noreferrer">
            Download the PDF here.
          </a>
        </p>
      </div>
    </div>
  );
};

export default Resume;