// ./Components/Pictures.js
import React from 'react';

const Pictures = () => {
  return (
    <div className="pictures-container">
      <h1>digital footprint of my life</h1>
      <p> coming soon ...</p>
    </div>
  );
};

export default Pictures;