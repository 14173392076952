import React from 'react';
import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const Footer = ({ theme, toggleTheme }) => {
  return (
    <header className="footer">
        <div className="footer-nav">
            {/* You need a left side to take up space */}
            <div className="footer-left" />

            {/* This will now be pushed to the right */}
            <div className="footer-right">
            <ul>
                <li><Link to="/">home</Link></li>
                <li><Link to="/projects">projects</Link></li>
                <li><Link to="/resume">resume</Link></li>
                <li><Link to="/pictures">pictures</Link></li>
            </ul>
            <button className="theme-toggle" onClick={toggleTheme}>
                {theme === 'dark' ? <Brightness7Icon/> : <Brightness4Icon />}
            </button>
            </div>
        </div>
    </header>
  );
};

export default Footer;
