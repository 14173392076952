import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Projects from './Components/Projects';
import Footer from './Components/Footer';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';  
import Resume from './Components/Resume';
import Pictures from './Components/Pictures'; 
import './App.css';

function App() {
    const [theme, setTheme] = useState('dark');
  
    const toggleTheme = () => {
        setTheme(prev => (prev === 'dark' ? 'light' : 'dark'));
      };
    
      // Apply theme to the body element
      useEffect(() => {
        document.body.className = theme;
      }, [theme]);
  
    return (
      <Router>
        <div className={`App ${theme}`}>
          {/* Short top bar */}
          <Footer theme={theme} toggleTheme={toggleTheme} />
  
          {/* This header will now be centered in the remaining space */}
          <Header />
  
          <main>
            <Content />
          </main>
        </div>
      </Router>
    );
  }
  

  const Header = () => {
    const location = useLocation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const interests = [
        "learning random things",
        "building passion projects",
        "going to the gym",
        "playing badminton",
        "running",
        "rock climbing", 
        "reading about game of thrones",
        "going out with friends", 
        "rewatching how i met your mother",
      ];
      
    const cycleInterest = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % interests.length);
    };
  
    return (
      location.pathname === '/' && (
        <header>
          <nav>
            <div className="nav-left">
              <h1>khoa-luong.com</h1>
              <p>i make things work ... sometimes</p>

              <p>I am a Computer Science student studying at Case Western Reserve University. Originally from Vietnam, I moved to the United States to pursue higher education.</p>
              
              <p>
                Here, I leave a digital footprint of my{" "}
                <Link
                    to="/projects"
                    style={{ textDecoration: 'underline', color: 'inherit' }}
                >
                    projects
                </Link>
                ,{" "}
                <Link to="/resume" style={{ textDecoration: 'underline', color: 'inherit' }}>
                    resume
                </Link>
                , and{" "}
                <Link to="/pictures" style={{ textDecoration: 'underline', color: 'inherit' }}>
                    pictures
                </Link>.
                </p>

                <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem', fontSize: '1.5rem' }}>
                    <a href="https://github.com/KhoaLuongCode" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                        <GitHubIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/khoaluongcwru" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                        <LinkedInIcon />
                    </a>
                    <a href="mailto:khoa.luong@case.edu" style={{ color: 'inherit' }}>
                        <EmailIcon />
                    </a>
                </div>


                <hr style={{ margin: '2rem 0', border: 'none', borderTop: '2px solid #ccc', color: 'inherit' }} />

                <div className="section">
                    <h2>Outside of work...</h2>
                    <p>
                        I am probably{" "}
                        <span 
                            onClick={cycleInterest} 
                            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'inherit' }}
                        >
                            {interests[currentIndex]}
                        </span>
                    </p>
                </div>


            </div>
          </nav>
        </header>
      )
    );
  };
  


const Content = () => {
  return (
    <Routes>
      <Route path="/projects" element={<Projects />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/pictures" element={<Pictures />} /> 
    </Routes>
  );
};

export default App;
