import React from 'react';
import '../App.css';
import GitHubIcon from '@mui/icons-material/GitHub';

const Projects = () => {
  return (
    <section className="projects-section">
      <h1>featured projects</h1>

      <a 
        href="https://github.com/KhoaLuongCode/hackathon-monday" 
        target="_blank" 
        rel="noopener noreferrer"
        className="project-item-link"
      >
        <div className="project-item">
          <h2>Monday</h2>
          <p>End-to-end AI agent that manages your email and schedules your plans.</p>
        </div>
      </a>

      <a 
        href="https://github.com/KhoaLuongCode/VietnameseFood_Predictor" 
        target="_blank" 
        rel="noopener noreferrer"
        className="project-item-link"
      >
        <div className="project-item">
          <h2>Vietnamese Food Predictor</h2>
          <p>Machine learning model trained to predict your favorite Vietnamese food.</p>
        </div>
      </a>

      <a 
        href="https://github.com/KhoaLuongCode/cwru-rmp" 
        target="_blank" 
        rel="noopener noreferrer"
        className="project-item-link"
      >
        <div className="project-item">
          <h2>CWRU Rate My Professors</h2>
          <p>Platform to rate and review professors at Case Western Reserve University.</p>
        </div>
      </a>

      <a 
        href="https://github.com/KhoaLuongCode/MatchMate" 
        target="_blank" 
        rel="noopener noreferrer"
        className="project-item-link"
      >
        <div className="project-item">
          <h2>Match Mate</h2>
          <p>Platform where you can look for fellow sport players within your community.</p>
        </div>
      </a>

      <a 
        href="https://github.com/isabellemyz/vocally-project" 
        target="_blank" 
        rel="noopener noreferrer"
        className="project-item-link"
      >
        <div className="project-item">
          <h2>Vocally</h2>
          <p>Building a tech interview platform.</p>
        </div>
      </a>
    </section>
  );
};

export default Projects;